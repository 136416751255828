import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import logo from './../../../shared/img/logo/logo_light.png';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
          <Link className="topbar__logo" to="/">
            <div style={{display: 'flex'}}>
              <h3 className="display-5">teacup.gg</h3>
              <b>&nbsp;BETA</b>
            </div>
          </Link>
          </div>
          <div className="topbar__right">
            <TopbarProfile user={this.props.user} updateUser={this.props.updateUser} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
