import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import AsyncCreatable from 'react-select/lib/AsyncCreatable'

const propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func,
};

const defaultProps = {
  user: null,
  handleSubmit: null,
};

class ChallongeImportForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      challongeURL: {
        value: '',
        label: '',
      },
      status: 'Enter the Challonge URL Code below.',
    };
  }

  loadBracket = (e) => {
    const { user, handleSubmit } = this.props;
    const { challongeURL } = this.state;
    e.preventDefault();
    const username = user.user;
    const userKey = user.key;
    const apiURL = `https://teacup-gg.herokuapp.com/init?user=${username}&key=${userKey}&id=${challongeURL.value}`;
    console.log(apiURL);
    fetch(apiURL, {
      method: 'get',
    })
      .then(
        (result) => {
          console.log('result returned.');
          result.json().then((data) => {
            console.log(data);
            if (data.isOwner) {
              handleSubmit(challongeURL.value);
            } else {
              this.setState({
                status: 'You are not authorized to load this bracket!',
              });
            }
          });
        },
        () => {
          this.setState({
            status: 'An error occurred!',
          });
        },
      );
  }

  updateURL = (selectedValue) => {
    console.log(selectedValue)
    this.setState({
      challongeURL: {
        value: selectedValue.value,
        label: this.challongeCodeToURL(selectedValue.value),
      },
    });
  }

  challongeCodeToURL = (challongeCode) => {
    let parts = challongeCode.split("-")
    if (parts.length > 1) {
      return 'https://' + parts[0] + '.challonge.com/' + parts.slice(1).join('-')
    }
    else if (parts.length > 0) {
      return 'https://' + 'challonge.com/' +challongeCode
    }
    return null
  }

  challongeURLToCode = (challongeURL) => {
    url = challongeURL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
    let parts = url.split('/')
    if(parts.length > 1){
      let code = parts[1]
      let hostParts = parts[0].split('.')
      if(hostParts.length > 2){
        let host = hostParts[0]
        return host+'-'+code
      }
      return code
    }
    return null
  }

  loadOptions = (inputValue, callback) => {
    const { user } = this.props;
    const apiURL = `https://teacup-gg.herokuapp.com/tournaments?id=${user.user}`;
    fetch(apiURL, {
      method: 'get',
    })
      .then(
        (result) => {
          console.log('result returned.');
          result.json().then((data) => {
            this.filterOptions(data, inputValue, callback);
          });
        },
        () => {
          console.log('error');
        },
      );
  }

  filterOptions = (data, inputValue, callback) => {
    const filteredOptions = [];
    console.log(data);
    data.forEach((bracket) => {
      if ('id' in bracket) {
        const urlCode = bracket.id
        const url = this.challongeCodeToURL(urlCode)
        if (url.toLowerCase().includes(inputValue.toLowerCase())) {
          filteredOptions.push({
            value: urlCode,
            label: url,
          });
        }
      }
    });
    callback(filteredOptions);
  }

  render() {
    const { status } = this.state;
    return (
      <div>
        <p><b>{status}</b></p>
        <br />
        <form className="form">
          <div className="form__form-group">
            <label htmlFor="challongeURL" className="form__form-group-label">Challonge Bracket</label>
            <AsyncCreatable
              name="challongeURL"
              value={this.state.challongeURL}
              onChange={this.updateURL}
              loadOptions={this.loadOptions}
              clearable={true}
              className="react-select"
              placeholder="Challonge Bracket URL or ID"
              classNamePrefix="react-select"
              defaultOptions={true}
            />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              color="primary"
              type="submit"
              onClick={e => this.loadBracket(e)}
            >
              Load
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    );
  }
}

ChallongeImportForm.propTypes = propTypes;
ChallongeImportForm.defaultProps = defaultProps;

export default ChallongeImportForm;
