import React from 'react';
import { Col, Container, Row, Jumbotron } from 'reactstrap';
import DesktopClientCard from './components/DesktopClientCard';
import ChromeExtensionCard from './components/ChromeExtensionCard';

const Download = () => (
  <Container className="dashboard">
    <Row>
      <Col>
        <Jumbotron style={{background: "transparent"}}>
          <Container>
            <h1 className="display-3">teacup.gg</h1>
            <p className="lead">The Stream Assistant for Challonge</p>
          </Container>
        </Jumbotron>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Downloads</h3>
      </Col>
    </Row>
    <Row>
      <DesktopClientCard />
    </Row>
    <Row>
      <ChromeExtensionCard />
    </Row>
  </Container>
);

export default Download;
