import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { withCookies } from 'react-cookie';
import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
  }

  componentWillReceiveProps() {
    this.forceUpdate();
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  logOut = () => {
    const { cookies } = this.props;
    cookies.remove('user');
    this.toggle();
    this.props.updateUser(null);
  }

  render() {
    const { collapse } = this.state;
    if (this.props.user) {
      return (
        <div className="topbar__profile">
          <button className="topbar__avatar" onClick={this.toggle}>
            <img className="topbar__avatar-img" src={Ava} alt="avatar" />
            <p className="topbar__avatar-name">{this.props.user.user}</p>
            <DownIcon className="topbar__icon" />
          </button>
          {collapse && <button className="topbar__back" onClick={this.toggle} />}
          <Collapse isOpen={collapse} className="topbar__menu-wrap">
            <div className="topbar__menu">
              <TopbarMenuLink title="Load Bracket" onClick={this.toggle} icon="list" path="/bracket" />
              <div className="topbar__menu-divider" />
              <TopbarMenuLink title="Log Out" onClick={this.logOut} icon="exit" path="/login" />
            </div>
          </Collapse>
        </div>
      );
    }
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">Account</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Log In" icon="exit" path="/login" onClick={this.toggle} />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default withCookies(TopbarProfile);
