import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import Bracket from '../Bracket/index';
import Landing from '../Landing/index';

const Router = (props) => {
  return (
    <MainWrapper>
    <main>
      <Switch>
      <div>
        <Layout user={props.user} updateUser={props.updateUser} />
        <div className="container__wrap">
          <Route exact path="/" component={Landing} />
          <Route exact path="/home" component={Landing} />
          <Route exact path="/download" component={Landing} />
          <Route exact path="/downloads" component={Landing} />
          <Route exact path="/login" render={(p) => <LogIn {...p} updateUser={props.updateUser} />} />
          <Route path="/bracket/:id?" render={(p) => <Bracket {...p} user={props.user} />} />
        </div>
      </div>
      </Switch>
    </main>
  </MainWrapper>
  );
}

export default Router;
